.interstitial-error-container {
  width: 100%;
  height: calc(100vh - #{$header-height});
  background-color: $body-bg;
}

.interstitial-error-box {
  width: 500px;
  height: 550px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: $error-text;
  padding: $spacing-large $spacing-large $spacing;
  text-align: center;
  button {
    margin-top: $spacing;
  }
}

.interstitial-error-icon {
  margin-bottom: $spacing-large;
}

.interstitial-error-title {
  font-size: $font-size-largest;
  margin-bottom: $spacing-small;
}

.interstitial-error-content {
  font-size: $font-size-medium;
  margin-top: $spacing-small;
}
