.header {
  $dropdown-bg: #606e71;

  width: 100%;
  display: flex;
  height: inherit;
  justify-content: space-between;
  background-color: $header-bg;
  padding: $spacing-small $spacing-root;
  align-items: center;
  position: fixed;
  z-index: 10;

  &-logo {
    background: url('../images/shell-lj-logo.svg') center no-repeat;
    width: 150px;
    display: inline-flex;
    height: inherit;
  }

  &-info {
    color: $white;
    display: flex;
    align-items: center;
    margin-right: -$spacing-root;

    p {
      color: $lj-ghost-light;
      margin-right: $spacing;
    }

    .lj-dropdown {
      width: 195px;
      background-color: $dropdown-bg;
      padding: 0.35rem 0;

      input {
        caret-color: $off-white;
      }

      &-primary {
        .lj-dropdown__dropdown-indicator svg {
          fill: $off-white;
        }
      }

      &__value-container {
        padding: 0;
      }

      &__control {
        padding: 0 $spacing;
      }

      &__control--menu-is-open {
        .lj-dropdown__single-value {
          color: $lj-ghost-light;
          background-color: transparent;
        }

        .lj-dropdown__value-container {
          border-bottom: 1px solid $off-white;
        }

        .lj-dropdown__input {
          color: $off-white;
        }
      }

      &__control--menu-is-open,
      &__control--menu-is-open:hover,
      &__control--menu,
      &__control,
      &__control:hover {
        border: none;
      }

      &__single-value {
        color: $off-white;
      }

      &__menu {
        width: 300px;
        right: 10px;
      }
    }
  }
}
