.invoices {
  .lj-label {
    color: $lj-headline;
  }

  .lj-table {
    &__body-menu {
      li {
        &:hover {
          background-color: $sidebar-link-bg-hover;
        }
        &:active {
          background-color: $sidebar-link-active;
        }
      }
    }
    &__body-cell {
      overflow-wrap: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      button {
        margin-right: $spacing-smaller;
        &:hover {
          background: $table-button-hover;
        }
        &:active {
          background: $btn-tertiary-active;
        }
        span {
          margin: $spacing-tiny 0;
        }
        svg {
          margin: 0;
        }
      }
    }
  }

  &__months-dropdown-container {
    margin-bottom: $spacing;
  }

  .lj-dropdown {
    width: 148px;
  }

  &__row-menu {
    border-radius: $border-radius;
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:hover {
      background: $table-button-hover;
    }

    svg {
      fill: $btn-tertiary-dark;
      stroke: $btn-tertiary-dark;
      color: $btn-tertiary-dark;
    }
  }
}
