// we are not using nested styles here because we want to take advantage of CSS
// specificity to override react-select's default styles.
.lj-dropdown {
  $dropdown-hover: #e8e8e8;
  $dropdown-active: #d5d8d5;
  $dropdown-option-hover: #e8e8e8;
  $dropdown-option-active: #d5d8d5;
  $selectedTextBg: #b3d3dd;
  $selectedText: #2a2f32;

  .group-title {
    font-size: $base-font-size;
  }

  .lj-dropdown__indicator-separator {
    display: none;
  }

  .lj-dropdown__value-container {
    overflow: initial;
  }

  .lj-dropdown__indicator {
    padding: 0;
    margin-right: $spacing-tiny;
  }

  .lj-dropdown__indicators {
    align-items: flex-end;
  }

  .lj-dropdown__input input {
    height: inherit;
  }

  .lj-dropdown__menu {
    background-color: $off-white;
  }

  .lj-dropdown__placeholder {
    color: $lj-ghost-light;
  }

  .lj-dropdown__option {
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: space-between;
    color: $lj-text-black;

    &:hover {
      background-color: $dropdown-option-hover;
    }

    &:active {
      background-color: $dropdown-option-active;
    }

    &--is-selected {
      color: inherit;
      background-color: $dropdown-option-active;
      font-weight: $font-weight-600;

      svg {
        align-self: center;
      }
    }
  }

  &-primary {
    .lj-dropdown__single-value {
      color: $lj-text-black;
    }

    .lj-dropdown__control {
      box-shadow: none;
      border: 1px solid $lj-headline;
      background: none;

      &:hover {
        background-color: none;
        border: 1px solid $lj-headline;
      }

      &--is-disabled {
        border-color: $lj-ghost-light;

        .lj-dropdown__dropdown-indicator {
          svg {
            fill: $lj-ghost-light;
          }
        }
      }

      &--menu-is-open {
        border-color: $btn-tertiary-dark;

        &:hover {
          border-color: $btn-tertiary-dark;
        }

        .lj-dropdown__single-value {
          color: $selectedText;
          background-color: $selectedTextBg;
        }

        .lj-dropdown__dropdown-indicator {
          padding-left: $spacing-tiny;
          transform: rotate(-180deg);
          align-self: flex-start;
          svg {
            fill: $btn-tertiary-dark;
          }
        }
      }
    }

    .lj-dropdown__dropdown-indicator {
      svg {
        fill: $lj-headline;
      }
    }
  }

  &-secondary {
    .lj-dropdown__placeholder,
    .lj-dropdown__single-value {
      color: $lj-green;
    }

    &:hover {
      background-color: $dropdown-option-hover;
    }

    .lj-dropdown__control {
      box-shadow: none;
      border: 2px solid transparent;
      background-color: transparent;

      &:hover {
        border: 2px solid transparent;
      }

      &--menu-is-open {
        border-color: $btn-tertiary-dark;

        &:hover {
          border-color: $btn-tertiary-dark;
        }

        .lj-dropdown__single-value {
          color: $selectedText;
          background-color: $selectedTextBg;
        }

        .lj-dropdown__dropdown-indicator {
          transform: rotate(-180deg);
          align-self: flex-start;
        }
      }
    }

    .lj-dropdown__dropdown-indicator {
      svg {
        fill: $btn-tertiary-dark;
      }
    }
  }
}
