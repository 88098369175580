$card-height: size(249);

.overview {
  h3 {
    font-size: $font-size;
  }

  &__contract-container {
    margin-bottom: $spacing-small;
    min-height: $card-height;
  }


  &-contracts,
  &-support {
    padding: $spacing-large;
    border-radius: $border-radius;
    background-color: $off-white;

    h3 {
      color: $lj-headline;
    }

    .row:first-child {
      margin-bottom: $spacing;
    }
  }

  &-contracts {
    margin-bottom: $spacing-small;
    height: 100%;

    .row {
      margin-bottom: $spacing-medium;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media only screen and (min-width: $viewport-sm) {
      margin-bottom: 0;
    }

    &__date {
      padding: 0 $spacing-tiny $spacing-tiny;

      &:last-child {
        padding-bottom: 0;
      }

      &--ending {
        background-color: $warning--light;
        padding: $spacing-tiny;
        border-radius: $border-radius;
      }
    }
  }

  &-support {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__contact-info {
      a {
        margin-bottom: $spacing-medium;
        width: 100%;
      }
    }

    p {
      margin-bottom: $spacing-small;
    }
  }

  &__news {
    min-height: size(192);
    border-bottom: 1px solid $body-bg;
    padding: $spacing-large;
    border-radius: $border-radius;
    background-color: $off-white;

    > div:nth-child(-n + 2) {
      margin-bottom: $spacing-small;
    }

    &--title {
      margin-bottom: $spacing;
      color: $lj-headline;
    }

    &--items {
      // The left-most news item needs no left padding.
      > div:first-child {
        .overview__news__item {
          padding-left: 0;
        }
    }
  }

  &__item {

      display: flex;
      flex-direction: column;
      padding-left: $spacing-large;
      padding-right: $spacing-large;

      &-title {
        display: -webkit-box;
        @include prefix(line-clamp, 3, webkit);
        @include prefix(box-orient, vertical, webkit);
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: $spacing-small;
      }

      &-date {
        color: $lj-ghost-dark;
        margin-bottom: size(16);
      }
    }
  }

  &-error {
    padding: $spacing-large;
    border-radius: $border-radius;
    background-color: $off-white;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    h3 {
      color: $lj-headline;
    }
  }
}
