// reset based off https://alligator.io/css/minimal-css-reset
html {
  box-sizing: border-box;
  font-size: $base-font-size;
  letter-spacing: $letter-spacing;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: $font-weight;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* General Styles */

body,
html,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family;
  color: $lj-text-black;
  overflow-x: hidden;
  line-height: $line-height;
  background-color: $body-bg;
}

@media print {
  .embeddedServiceHelpButton,
  #appzi-launch-button-3bf5e2e9-a8ab-4730-93e1-ae93bd69f932,
  .nav-side,
  .row-header,
  .page-header {
    visibility: hidden;
  }

  body {
    background-color: #fff;
  }
}

// some browsers add default margin to pages when they print
// so we want to remove that for the invoice page
@page {
  margin: 0;
}
