.tippy-box[data-theme~='dark'] {
  background-color: $header-bg;
  color: $off-white;
}

.tippy-box {
  padding: $spacing-tiny;
  border-radius: $border-radius;
  box-shadow: 0px 0px 2px rgba(63, 67, 70, 0.4), 0px 2px 4px rgba(63, 67, 70, 0.4);
  opacity: 0.9;
}