// Structure inspired by https://github.com/jgthms/bulma/tree/master/sass
// and uses http://flexboxgrid.com/ to for the grid

// The REM formula is TARGET / BASE with target being
// your desire font size and base being the base
// size. E.g if your base is 14px and you want to
// calculcate 32px, then 32 / 14 = 2.285rem

// For convenience there is a SCSS function size(value) that takes in a value in px and converts it to rem

/************************************************
* LAYOUT
*************************************************/
$spacing-largest: size(56);
$spacing-large: size(32);
$spacing: size(24);
$spacing-medium: size(16);
$spacing-root: size(14);
$spacing-small: size(12);
$spacing-smaller: size(10);
$spacing-tiny: size(8);
$spacing-tiniest: size(6);

$sidebar-link-padding: size(20);
$label-margin: size(3.6);

$header-height: size(48);

// flexbox media queries viewports
// TO DO: create mixing using https://css-tricks.com/approaches-media-queries-sass/
// TO DO $viewport-xs: // 768px and below
$viewport-sm: 48em; // 768px and above
$viewport-md: 64em; // 1024px and above
$viewport-lg: 75em; // 1200px and above
// TO DO $viewport-xl: // 1440 and above

/************************************************
* FONTS
*************************************************/
$base-font-size: 14px;
$font-family: 'Roboto', sans-serif, helvetica, arial;
$font-size-extra-large: size(32);
$font-size-largest: size(20);
$font-size-large: size(16);
$font-size: size(14);
$font-size-medium: size(12);
$font-size-small: size(10);
$line-height: size(21);
$letter-spacing: size(0.42);

$font-weight: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;

/************************************************
* GENERAL COLOURS
*************************************************/

$lj-text-black: #1d2c27;
$lj-headline: #606e71;
$lj-ghost-dark: #5e6e69;
$lj-ghost-light: #bdbdbd;
$lj-green: #135a52;
$lj-green-hover: #00ce4f;
$lj-dark-green: #4c7b77;
$lg-primary-green: #00d953;
$lj-bright-green: #37ef93;
$lj-bright-green-light: rgba(55, 239, 147, 0.3);
$lj-bright-green-hover: #2bda82;
$white: #fff;
$black: #000;
$off-white: #f9f9f9;
$address-grey: #8f9697;
$address-dark-grey: #454f52;

/************************************************
* UI SPECIFIC COLOURS
*************************************************/

$body-bg: #f3f4f4;
$header-bg: #2a2f32;
$spinner: rgba(76, 123, 119, 0.5);

$sidebar-bg: #ecedee;
$sidebar-border: #e0e0e0;
$sidebar-link-bg: #dcdcdc;
$sidebar-link: #5d6365;
$sidebar-link-bg-hover: #e8e8e8;
$sidebar-link-active: #e1e1e1;

$page-header-bg: #ecedee;
$page-header-border: #e0e0e0;

$tertiary-bg: #135a52;
$btn-primary-txt: #00205c;
$btn-old-primary: #f9f9f9;
$btn-secondary: $lj-dark-green;
$btn-secondary-light-hover: #e2e7e7;
$btn-tertiary-hover: #dbe5e2;
$btn-tertiary-active: #c9d7d4;
$btn-tertiary-dark: $lj-dark-green;
$btn-tertiary-dark-hover: #135a52;
$btn-tertiary-disabled: rgba(76, 123, 119, 0.3);

$loader-bg: #0e4c45;
$loader-bg-light: rgba(14, 76, 69, 0.3);

$table-button-hover: #dbe5e2;
$table-row-hover: #e7edeb;

$settlement-period-hour: #606e71;

/************************************************
* COLOURS - PILLS
*************************************************/
$pill-jazzy: #ddd5f5;
$pill-jazzy-text: #5e39ce;

/************************************************
* COLOURS - ALERTS
*************************************************/
$error: #d7333b;
$error-text: #5e6e69;
$error-light: #e2747a;
$error-lighter: #f9cfd1;
$error-bg: #ffebec;

$warning: #ecad0c;
$warning--light: rgba(236, 173, 12, 0.3);

$alert-info: #d4e4e0;
$alert-jazzy: #ddd5f5;
$alert-jazzy-text: #443570;
$alert-jazzy-link: #4b28b7;
$alert-caution: #f9e6b6;
$alert-caution-text: #6c4100;
$alert-success: #cbf0b9;
$alert-success-text: #0e4c45;
$alert-warning: #e3cbcc;
$alert-warning-text: #91191f;

/************************************************
* Highlighting & Decoration
*************************************************/
$light-opacity: 0.7;
$disabled-button-opacity: 0.33;
$border-general: 1px solid $sidebar-border;
$border-radius: size(4);
$dropdown-inset: size(2);

$dropdown-box-shadow: 4px 6px 20px rgba(19, 90, 82, 0.13),
  0px 4px 4px rgba(81, 159, 45, 0.06);
