.lj-skeleton {
  display: flex;

  &__element {
    background-size: 200px 100%;
    background-repeat: no-repeat;
    display: inline-block;
    line-height: 1;
    width: 100%;
    height: 100%;

    @keyframes skeletonFrame {
      0% {
        background-position: -200px 0;
      }
      100% {
        background-position: calc(200px + 100%) 0;
      }
    }
  }
}