.container-app {
  padding: 0;

  .contact {
    background-color: $black;
    border-radius: 7px 7px 0 0;
    position: fixed;
    width: 147px;
    height: 46px;
    z-index: 100;
    bottom: 0;
    right: 15px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    line-height: 1;

    &-btn {
      color: $white;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-family;
      font-size: 0.875em;
      text-decoration: none;
    }
  }

  .row {
    &-header {
      height: $header-height;
      margin: 0;
    }

    &-body {
      margin: 0;
    }
  }
}
