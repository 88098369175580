.asset-properties-dropdown {
  .lj-dropdown {
    width: 324px;
  }

  p {
    display: inline-flex;
  }

  &__email {
    display: flex;
    align-items: center;
  }
}

.asset-details {
  &__col {
    margin-bottom: $spacing-large;

    &:last-child {
      margin: 0;
    }
  }

  &__title {
    color: $lj-headline;
    font-size: $font-size-medium;
    display: inline-flex;
    margin-right: $spacing-tiniest;

    &-container {
      display: flex;

      svg {
        fill: $lj-dark-green;
      }
    }
  }

  &__value {
    font-weight: $font-weight-500;
    margin-top: $spacing-root;
  }

  @media only screen and (min-width: $viewport-sm) {
    &__col {
      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-top: 0;
      }
    }

    &__value {
      margin-top: $spacing-tiniest;
    }
  }
}