.select-company {
  width: 400px;
  margin: $spacing auto;
  background-color: $off-white;
  padding: $spacing-small 0;
  color: $black;
  border-radius: $border-radius;
  box-shadow: 4px 6px 20px rgba(19, 90, 82, 0.13),
    0px 4px 4px rgba(81, 159, 45, 0.06);

  p {
    margin-bottom: $spacing-small;
    font-size: $font-size-large;
    padding: 0 $spacing-small;
  }
  /* these dropdown styles only appear like this on the select company screen */
  .lj-dropdown__control {
    margin: 0 $spacing-small;
  }

  .lj-dropdown__indicators {
    display: none;
  }

  .lj-dropdown__menu {
    position: initial;
    top: initial;
    box-shadow: none;
    margin-bottom: 0;

    &-list {
      padding: 0;
    }
  }

  .lj-dropdown__option {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
  }
}
