.invoice_header {
  button[name='print-invoice-button'] {
    margin:0 auto;
    display:block;
  }
  @media print {
    button[name='print-invoice-button'] {
      visibility: hidden;
    }
  }

  &-title {
    margin: size(64) 0 0;

    svg {
      margin-left: size(64);
    }

    p {
      margin-right: size(64);
      align-self: center;
      font-weight: $font-weight-500;
      font-size: size(30);
    }
  }

  &-content {
    margin: size(47) size(64);

    &__title {
      font-size: size(30);
      font-weight: $font-weight-500;
      margin-bottom: $spacing-small;
    }

    &__subtitle {
      font-weight: $font-weight-500;
      margin-bottom: $spacing-medium;
    }

    &__cards {
      display: flex;
      flex-direction: column;
    }

    &__card-invoice,
    &__card-amount,
    &__card-tax {
      width: 208px;
      height: 59px;
      display: inline-flex;
      flex-direction: column;
      color: $header-bg;
      align-items: center;
      justify-content: center;
      padding: $spacing-tiniest;
    }

    &__card-invoice,
    &__card-tax {
      background-color: $sidebar-bg;
    }

    &__card-amount {
      background-color: $lj-bright-green;
    }

    &__card-key {
      text-transform: uppercase;
      font-size: $font-size-medium;
    }

    &__card-value {
      text-transform: uppercase;
      font-size: $font-size-large;
      font-weight: $font-weight-500;
    }

    &__address {
      padding-right: $spacing-tiny;

      &--loading:not(:last-child) {
        margin-bottom: $spacing-tiny;
      }

      &-invoice {
        margin-bottom: $spacing-tiny;

        .invoice_header-content__address-value {
          color: $lj-text-black;
        }
      }

      &-vat, &-date {
        margin-top: $spacing-tiny;

        .invoice_header-content__address-value {
          color: $lj-text-black;
        }
      }

      &-key {
        text-transform: uppercase;
        color: $address-grey;
        font-size: $font-size-medium;
        font-weight: $font-weight-500;
        align-self: baseline;
      }

      &-value {
        color: $address-dark-grey;
        font-weight: $font-weight-500;
        overflow-wrap: break-word;
        width: 200px;
        text-align: right;
      }

      &-line {
        font-size: $font-size-medium;
        color: $address-grey;
      }
    }
  }
}