$bg-color: #e7edeb;

.notification__card {
  background-color: $bg-color;
  position: relative;

  @media only screen and (min-width: $viewport-md) {
    position: fixed;
    width: 380px;
  }

  svg {
    position: absolute;
    top: 18px;
    right: 18px;

    &:focus,
    &:active {
      outline: 1px auto $lj-bright-green;
    }
  }

  &-title,
  &-text {
    margin-bottom: $spacing;
  }

  &-title {
    color: $alert-success-text;
    font-size: $font-size-large;
  }

  &-text,
  &-contact {
    color: $lj-headline;
  }

  &-text:last-child {
    margin-bottom: 0;
  }

  &-contact {
    margin-bottom: $spacing-root;
  }

  &-contactInfo {
    color: $lj-dark-green;
  }
}
