.iframe-container {
    position: relative;
    margin: -24px;
    padding: $spacing;

    iframe {
        border: 0;
        overflow: hidden;
        height: 100vh;
        width: 100%;
    }

    .spinner {
        position: absolute;
        top: 30%;
        left: 50%;
    }
}
