.page-container {
  height: 100%;
  width: calc(100% - 220px);
  position: relative;
  left: 220px;
  z-index: 1;

  &__center {
    max-width: 1200px;
    margin: 0 auto;
    padding: $spacing;
  }
}