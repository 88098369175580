.error-container {
    width: 100%;
    height: 100vh;
    background-color: $body-bg;
}

.error-box {
    width: 500px;
    height: 550px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    color: $error-text;
    padding: $spacing-large $spacing-large $spacing;
    text-align: center;
}

.errorIcon {
    margin-bottom: $spacing-large;
}

.errorTitle {
    font-size: $font-size-largest;
    margin-bottom: $spacing-small;
}

.errorCode {
    font-size: $font-size-medium;
    margin-bottom: $spacing-large;
    margin-top: $spacing-small;
}