.lj-alert {
  background-color: $page-header-bg; // So when the fadeIndown occurs there isn't a white background until the animation finishes.

  &--floating {
    animation: fadeIn 0.5s ease-in both;
    position: absolute;
    min-width: 100%;
    z-index: 700; // This ensures it displays above the content on iframe pages.
  }

  &__container {
    padding: $spacing-tiny $spacing;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;

    &--info {
      background-color: $alert-info;
    }

    &--jazzy {
      background-color: $alert-jazzy;
      color: $alert-jazzy-text;

      // a.lj-link needs to be this specific to keep colour on hover
      a.lj-link,
      button {
        color: $alert-jazzy-link;
      }
    }

    &--caution {
      background-color: $alert-caution;
      color: $alert-caution-text;

      a.lj-link,
      button {
        color: $alert-caution-text;
      }
    }

    &--success {
      background-color: $alert-success;
      color: $alert-success-text;
    }

    &--warning {
      background-color: $alert-warning;
      color: $alert-warning-text;

      a.lj-link,
      button {
        color: $alert-warning-text;
      }
    }

    &--warning,
    &--success {
      align-items: center;
      flex-direction: column;
    }
  }

  &__text {
    display: flex;
  }

  &__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: transparent;
    display: flex;
    align-items: center;
    svg {
      padding: $spacing-tiniest 0;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
