.lj-input {
  padding: $spacing-small $spacing-smaller;
  border-radius: $border-radius;
  width: 100%;
  position: relative;

  &::placeholder {
    color: $lj-ghost-light;
  }

  // loading animation taken from https://codepen.io/zsherman/pen/bRrMoQ
  &--loading {
    height: 0.15rem;
    background: #aad1f9;
    margin: 0 auto;
    -webkit-animation: start 0.3s ease-in;
    animation: start 0.3s ease-in;
    position: relative;
    overflow: hidden;
    border-radius: 1px;
    width: 100%;
    margin-bottom: 1px;

    &__bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      transition: transform 0.2s linear, -webkit-transform 0.2s linear;
      background: #106cc8;

      &1 {
        animation: growBar1 2.5s infinite, moveBar1 2.5s infinite;
      }

      &2 {
        animation: growBar2 2.5s infinite, moveBar2 2.5s infinite;
      }
    }

    @keyframes start {
      from {
        max-height: 0;
        opacity: 0;
      }
      to {
        max-height: 20px;
        opacity: 1;
      }
    }

    @keyframes growBar1 {
      0% {
        animation-timing-function: linear;
        transform: scaleX(0.1);
      }
      36.6% {
        animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
        transform: scaleX(0.1);
      }
      69.15% {
        animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
        transform: scaleX(0.83);
      }
      100% {
        transform: scaleX(0.1);
      }
    }

    @keyframes growBar2 {
      0% {
        animation-timing-function: cubic-bezier(
          0.20503,
          0.05705,
          0.57661,
          0.45397
        );
        transform: scaleX(0.1);
      }
      19.15% {
        animation-timing-function: cubic-bezier(
          0.15231,
          0.19643,
          0.64837,
          1.00432
        );
        transform: scaleX(0.57);
      }
      44.15% {
        animation-timing-function: cubic-bezier(
          0.25776,
          -0.00316,
          0.21176,
          1.38179
        );
        transform: scaleX(0.91);
      }
      100% {
        transform: scaleX(0.1);
      }
    }

    @keyframes moveBar1 {
      0% {
        left: -105.16667%;
        animation-timing-function: linear;
      }
      25% {
        left: -105.16667%;
        animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
      }
      69.15% {
        left: 21.5%;
        animation-timing-function: cubic-bezier(
          0.30244,
          0.38135,
          0.55,
          0.95635
        );
      }
      100% {
        left: 95.44444%;
      }
    }

    @keyframes moveBar2 {
      0% {
        left: -54.88889%;
        animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
      }
      25% {
        left: -17.25%;
        animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
      }
      48.35% {
        left: 29.5%;
        animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
      }
      100% {
        left: 117.38889%;
      }
    }
  }

  &--light {
    border: 1px solid $lj-headline;

    &:active {
      border: 1px solid $lj-green;
    }

    &:focus {
      outline-color: $lj-green;
    }
  }

  &--dark {
    border: 2px solid transparent;

    &:active {
      border: 2px solid $lj-bright-green;
    }

    &:focus {
      outline-color: $lj-bright-green;
    }
  }

  &__symbol {
    position: absolute;
    color: $lj-ghost-light;
    right: $spacing-smaller;
    transform: translateY(size(12));
  }
}
