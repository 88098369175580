.invoice_table {
  margin: 0 size(64);

  &__head {
    margin-bottom: 0;
    padding: $spacing-tiniest;
    background-color: $sidebar-bg;

    th.lj-table__head-cell {
      flex: 100 0 auto;
      min-width: 0px;
      width: 100px;
      cursor: initial;
      font-weight: $font-weight-500;
      color: $header-bg;
      text-align: center;
      display: block;

      &__ppa:first-child {
        width: 305px;
      }
    }
  }
  &__body {
    border-bottom: none;
    border-left: none;
    background-color: $white;

    
    td.lj-table__body-cell {
      flex: 100 0 auto;
      min-width: 0px;
      width: 100px;
      border-left: 1px solid $sidebar-link-active;
      text-align: center;
      cursor: initial;
      padding: $spacing-tiny;
      color: $lj-headline;

      &__ppa:first-child {
        width: 305px;
      }
    }

    tr {
      height: 30.9px;
    }
  
    &--net,
    &--gross,
    &--vat {
      border-bottom: 0;
      height: 30.9px;

      td.invoice_table__cell-summary  {
        border-left: none;

        &__ppa:first-child {
          width: 305px;
        }
      }

      .invoice_table__cell-summary-amount {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30.9px;
      }
    }

    &--net,
    &--vat {
      .invoice_table__cell-summary-amount {
        background-color: $lj-bright-green-light;
        
      }
    }

    &--net,
    &--vat {
      .invoice_table__cell-summary-name,
      .invoice_table__cell-summary-amount {
        border-bottom: 1px solid $sidebar-link-active;
      }
    }

    &--gross {
      .invoice_table__cell-summary-name {
        color: $header-bg;
      }

      .invoice_table__cell-summary-name,
      .invoice_table__cell-summary-amount {
        background-color: $lj-bright-green;
        border-bottom: 1px solid $sidebar-link-active;
      }
    }
  }
} 