.lj-link {
  &--default {
    color: $btn-tertiary-dark;
    font-weight: $font-weight-500;
    &:hover {
      color: $btn-tertiary-dark-hover;
    }

    &:focus {
      color: $btn-tertiary-dark-hover;
    }
  }

  &--dropdown {
    &:focus {
      outline: 2px solid $lj-bright-green; 
      outline-offset: -$dropdown-inset;
    }
  }

  &--dropdown, &--dropdown:visited {
    font-weight: normal;
    text-decoration: none;
    color: $lj-text-black;

  }

  &--disabled, &--disabled:active, &--disabled:visited {
    color: $lj-ghost-light;
    pointer-events: none;
  }
}

// Buttons that look like text
button.lj-link {
  color: $lj-text-black;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight-500;
  line-height: $line-height;
  text-decoration: underline;
  cursor: pointer;

  background: none !important;
  margin: auto;
  padding: unset;
  border: unset;
}