.nav-side {
  background-color: $sidebar-bg;
  width: 220px;
  padding: 2.5rem 0 4.14rem;
  border-right: 1px solid $sidebar-border;
  z-index: 2;
  overflow-x: auto;
  @include position(
    fixed,
    $top: $header-height,
    $bottom: 0
  ); // the top value is always the height of the header

  &__btn-logout {
    margin-top: auto;
    padding: $sidebar-link-padding;
    display: flex;
    align-items: center;

    .logout {
      color: $sidebar-link;
      padding: 0;
      font-size: $font-size;
      &:hover {
        background-color: transparent;
      }
    }

    svg {
      stroke: rgb(93, 99, 101);
      stroke-width: 2;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    height: 100%;

    li {
      button svg {
        stroke: $sidebar-link;
      }
      a {
        text-decoration: none;
        color: $sidebar-link;
        font-size: $font-size-medium;
        display: flex;
        padding: $spacing-medium $sidebar-link-padding;
        align-items: flex-end;

        &:hover {
          background-color: $sidebar-link-bg-hover;
        }

        svg {
          margin-right: $spacing-tiny;
        }

        &.active {
          background-color: $sidebar-link-active;
          color: $black;
        }
      }
    }
  }
}
