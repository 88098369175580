/* This needs to be refactored into wrapper components for the login message container and footer  */

.password-reset {
  &__sendEmail-msg-container,
  &__requestSuccess-msg-container,
  &__success-msg-container,
  &__choose-msg-container {
    @extend .login-container__welcome;
  }

  .contact {
    background-color: $black;
    border-radius: 7px 7px 0 0;
    position: fixed;
    width: 147px;
    height: 46px;
    z-index: 100;
    bottom: 0;
    right: 15%;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    line-height: 1;

    &-btn {
      color: $white;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-family;
      font-size: 0.875em;
      text-decoration: none;
    }
  }

  &__sendEmail-footer,
  &__requestSuccess-footer,
  &__success-footer,
  &__choose-footer {
    @extend .login-container__footer;
  }

  &__sendEmail {
    &-msg {
      margin-bottom: $spacing-small;
      font-size: $font-size-large;
    }
  }

  &__requestSuccess {
    &-msg-done {
      font-size: $font-size-large;
      margin-bottom: $spacing;
    }
  }

  &__success-msg-container___text,
  &__choose-msg-container__text {
    font-size: $font-size-large;
  }
}
