.login-container {
  $spacing-large: 4rem; // 56px

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 400px;
  height: 474px;
  display: flex;
  flex-direction: column;

  .contact {
    background-color: $black;
    border-radius: 7px 7px 0 0;
    position: fixed;
    width: 147px;
    height: 46px;
    z-index: 100;
    bottom: 0;
    right: 15%;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    line-height: 1;

    &-btn {
      color: $white;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-family;
      font-size: 0.875em;
      text-decoration: none;
    }
  }

  form {
    border: 1px solid $off-white;
    color: $off-white;
    padding: $spacing;
    border-radius: $border-radius;
    margin-bottom: $spacing;
    // height: 100%;
  }

  &__welcome {
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacing-large;

    .logo {
      margin-bottom: 25px;
    }

    &-msg {
      font-size: $font-size-large;
    }
  }

  &__footer {
    margin-top: calc(#{$spacing} * 3);
    margin-bottom: $spacing-medium;
  }

  .credit {
    color: $address-grey;
    font-size: $font-size-medium;
    align-self: center;
  }

  .privacy {
    color: $off-white;
    font-size: $font-size-medium;
    align-self: center;
    margin-bottom: $spacing-tiny;
  }
}
