.page-header {
  padding: $spacing;
  background-color: $page-header-bg;
  height: 100px;
  border-bottom: 1px solid $page-header-border;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .subheading__container {
    display: flex;
  }
}

.page-title-block {
  display: flex;
}
