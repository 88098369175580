.lj-pill {
    font-size: $font-size;
    border-radius: 8px;
    padding: 0px 4px 0px 4px;
    align-self: center;
    margin-left: 7px;
    font-size: $font-size-medium;
    text-transform: uppercase;

    &--jazzy {
        color: #5E39CE;
        background-color: #DDD5F5;
    }
}