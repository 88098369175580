.lj-table {  
  width: 100%;
  display: block;

  &__head {
    display: block;
    margin-bottom: $spacing-small;

    &-cell {
      color: $lj-ghost-dark;
      font-weight: $font-weight;   
      font-size: $font-size-medium;

      &:first-child {
        padding-left: $spacing;
      }

      &:last-child {
        padding-right: $spacing;
      }
    }
  }

  &__body {
    display: block;


    &__no-data {
      color: $lj-headline;
      font-weight: $font-weight;   
      font-size: $font-size-medium;
      margin: $spacing-medium;
      text-align: center;
    }

    &-cell {
      padding: $spacing-root 0;
      font-size: $font-size-medium;
      font-weight: $font-weight;
      line-height: 14px;

      &:first-child {
        padding-left: $spacing;
        font-size: $font-size;
        font-weight: $font-weight-500;
      }

      &:nth-child(3) {
        padding-right: 70px;
        
      }
      &:nth-child(7) {
        font-size: $font-size;
        font-weight: $font-weight-500;
      }

      &:last-child {
        padding-right: $spacing;
      }
    }

    &-menu {
      position: relative;

      &-container {
        position: absolute;
        width: 256px;
        background: $white;
        border-radius: $border-radius;
        padding: $spacing-tiny 0px $spacing-small 0px;
        text-align: left;
        right: 0;
        top: 0;
        @include prefix(box-shadow, 0px 4px 24px 0px rgba(76, 123, 119, 0.24), (webkit, moz));
        
        ul li {
          height: 40px;

        }

        ul li a {
          display: flex;
          padding: $spacing-tiny $spacing-small $spacing-tiny $spacing-small;
          height: 40px;
          align-items: center;
          
        }
      }
    }
  }

  &__head-cell {
    display: flex;
    align-items: center;
  }

 
  &__head-row{
    border-bottom: 1px solid $sidebar-border;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }
  }

  &__body-row {
    display: flex;
    align-items: center;
    height: 56px;

    &:nth-child(odd) {
      background: #FFFFFF;
    }
    &:nth-child(even) {
      background-color: #F9F9F9;
    }

    &:first-child {
      border-radius: 4px 4px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }

    &:hover {

       > .lj-table__body-cell button {
        background-color: $table-button-hover;
      }
      background-color: $table-row-hover;
    }
    &:active {
      > .lj-table__body-cell button {
        background-color: $btn-tertiary-active;
      }
    }
  }

  
  &__body-cell {
    cursor: pointer;
    text-align: left;
  }

  &__pagination {
    padding: $spacing;
    color: $lj-ghost-dark;
    text-align: center;
    select,
    button {
      color: $lj-ghost-dark;
      padding: 5px;
    }
  }
}
