$bg-color: #E7EDEB;

.lj-checkbox {
  background-color: $bg-color;
  border-radius: $border-radius;
  padding: $spacing-smaller;
  display: flex;
  position: relative;
  @include prefix(user-select, none, (webkit, moz, ms));
  cursor: pointer;
  padding-left: size(40);

  &--error {
    border: 1px solid $alert-warning-text;
  }

  &:focus {
    outline: 1px solid $lj-bright-green;
    outline-style: auto;
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    height: size(16);
    width: size(16);
    border-radius: $border-radius;
    background-color: $white;
    align-self: center;
    position: absolute;
    left: 12px;
  }

  &__input:checked ~ &__checkmark {
    background-color: $lj-dark-green;
  }

  &__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  &__input:checked ~ &__checkmark:after {
    display: block;
  }

  &__checkmark:after {
    left: size(6);
    top: size(3);
    width: size(5);
    height: size(9);
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}