.lj-label {
  display: inline-flex;
  margin-bottom: $label-margin;
  font-size: $font-size-medium;
  
  &--light {
    color: $lj-headline;

    &:active {
      color: $lj-green;
    }
  }

  &--dark {
    color: $body-bg;

    &:active {
      color: $lj-bright-green;
    }
  }
}