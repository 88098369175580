.invoice_footer {
  margin: size(47) size(64);
  
  text-align: right;
  &-container-address {
    margin-bottom: $spacing;
    div {
      padding-left: $spacing-largest;
    }
    .footer-title {
      color: #8f9697;
      text-transform: uppercase;
    }
  }

  &-container-tax {
    background: $sidebar-bg;

    p {
      margin: 0 auto;
    }
  }

  &-container-contact {
    margin: $spacing auto;
    text-align: center;

    a,
    span {
      padding: 0 $spacing-tiniest;
    }
  }
}