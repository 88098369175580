.btn {
  cursor: pointer;
  border: 0;
  border-radius: $border-radius;
  font-family: $font-family;
  padding: $spacing-small $spacing-medium;
  font-size: $font-size;
  display: inline-flex;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    margin-left: $spacing-tiny;
    align-self: center;
  }

  &-full {
    width: 100%;
  }

  &-primary {
    background-color: $lg-primary-green;
    color: $btn-primary-txt;
    font-size: $font-size;
    font-weight: $font-weight-700;

    &:hover {
      background-color: $lj-green-hover;
    }
    &:active {
      background-color: #00c34b;
    }

    &-bright {
      background-color: $lj-bright-green;
      color: $lj-text-black;

      &:hover {
        background-color: $lj-bright-green-hover;
      }

      &:focus {
        background-color: $lj-bright-green-hover;
        outline: 2px auto $lj-bright-green;
      }

      &:disabled {
        opacity: $disabled-button-opacity;
      }
    }
  }

  &-secondary,
  &-secondary-light {
    border-width: 2px;
    border-style: solid;
    background-color: transparent;
  }

  &-secondary {
    color: $btn-secondary;
    border-color: $btn-secondary;

    &:hover {
      border-color: $lj-green;
      color: $lj-green;
    }

    &:focus {
      outline: 2px auto $lj-bright-green;
    }

    &:disabled {
      opacity: $disabled-button-opacity;
    }
  }

  &-secondary-light {
    color: $off-white;
    border-color: $off-white;

    &:hover {
      border-color: $btn-secondary-light-hover;
      color: $btn-secondary-light-hover;
    }

    &:focus {
      outline: 2px auto $lj-bright-green;
    }

    &:disabled {
      opacity: $disabled-button-opacity;
    }
  }

  &-tertiary,
  &-tertiary-light,
  &-tertiary-icon {
    padding: $spacing-smaller $spacing-small;
    background-color: transparent;
    letter-spacing: $letter-spacing;
  }

  &-tertiary,
  &-tertiary-icon {
    color: $btn-tertiary-dark;
    background: transparent;

    &:disabled {
      &:hover {
        background-color: $off-white;
      }
    }

    &:hover {
      background-color: $btn-tertiary-hover;
    }

    &:focus {
      outline: 2px auto $lj-bright-green;
    }

    &:active {
      background-color: $btn-tertiary-active;
    }
  }

  &-tertiary-icon {
    width: 36px;
    height: 36px;
    padding: 0;

    svg {
      margin: $spacing-smaller auto;
      display: block;
    }
  }

  &-tertiary-light {
    color: $btn-old-primary;
    text-decoration: underline;

    &:focus {
      outline: 2px auto $lj-bright-green;
    }

    &:disabled {
      opacity: $disabled-button-opacity;
    }
  }
}
