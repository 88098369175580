.error-general {
  height: 500px;

  p {
    margin-bottom: $spacing;
  }

  button:first-of-type {
    margin-right: $spacing;
  }
}