.lj-form {
  &-group {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-large;
  }
  &-error {
    color: $error;
    display: flex;
    align-items: center;

    svg {
      margin-right: $spacing-tiny;
      min-width: 14px;
      fill: $error;
    }

    &__text {
      font-size: $font-size-medium;
    }

    &--dark {
      color: $error-light;

      svg {
        fill: $error-light;
      }
    }
  }
}
