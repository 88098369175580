.outage-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    text-decoration: none;
  }

  .planned-anchor {
    margin-right: 16px;
  }

  .unplanned-anchor {
    margin-left: 16px;
  }
}
