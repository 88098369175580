/* IMPLEMENTATION NOTE
  We have used flexbox to allow avoid having to give widths to each settlement 
  period of availability block. The flex-grow property means that all the li 
  elements in our list of settlement periods will all have the same width. If we
  add more elements, we do not have to worry about increasing the width of the parent
  container to avoid the elements being squashed or overflowing, for example. 
*/

.availability {
  &-controls {
    .lj-dropdown {
      width: 324px;
      margin-bottom: $spacing;
    }
  }

  &-dropdown-container {
    padding-left: size(7);
  }

  &-legend-container {
    font-size: $font-size-medium;
    display: flex;

    li {
      padding: 0 $spacing-tiny;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-transform: capitalize;
    }
  }

  &-legend {
    height: 20px;
    width: 16px;
    margin-right: $spacing-tiniest;
    border-radius: 2px;

    &-available {
      background-color: $lj-bright-green;
    }

    &-must_run {
      background-color: $lj-bright-green;
      border: 2px solid;
    }

    &-unavailable {
      background-color: $sidebar-bg;
      hr {
        margin: $spacing-tiny 0;
        height: 2px;
        color: $black;
        background: $black;
        border: 0;
      }
    }
  }

  &-pagination-container {
    button {
      font-weight: $font-weight-500;
      margin-right: $spacing-tiniest;

      svg {
        stroke: $btn-tertiary-dark;
        height: 14px;
        width: 14px;
      }
    }

    p {
      line-height: $spacing-large;
      font-weight: $font-weight-500;
    }
  }

  /******************************** 
    Timeline styles
  *********************************/

  &-timeline {
    background-color: $white;
    border-radius: $border-radius;
    margin-top: $spacing;
    padding: $spacing;

    .interstitial-error-container {
      background: transparent;
      position: relative;
      p,
      button {
        margin: $spacing-tiniest;
      }
      .interstitial-error-icon {
        margin-bottom: 0;

        svg {
          width: 40px;
        }
      }
    }

    &--loading:not(:last-child) {
      margin-bottom: $spacing;
    }

    .asset-timeline {
      display: flex;
      width: 100%;
      margin-bottom: $spacing-medium;

      &:last-child {
        margin-bottom: 0;
      }

      &__date {
        width: 60px;
        margin-right: $spacing;
        align-self: baseline;
        font-weight: $font-weight-500;

        &--highlighted {
          background-color: $alert-caution;
          border-radius: size(4);
          padding: size(4);
        }
      }

      &__data {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      &__status {
        height: size(20);

        &-container {
          height: 100%;
          display: flex;
          margin: 0;
          padding: 0;
          justify-content: space-between;
        }

        &-sp {
          flex-grow: 1;
          height: 100%;
          width: size(1);

          &--available {
            background-color: $lj-bright-green;
          }

          &--available.sp-first {
            svg {
              transform: translate(2px, 5px);
            }
          }

          &--unavailable {
            background-color: $sidebar-bg;

            hr {
              width: 100%;
              background-color: black;
              border: none;
              height: size(2);
            }
          }

          &--must-run {
            background-color: $lj-bright-green;
            border-top: size(2) solid $header-bg;
            border-bottom: size(2) solid $header-bg;

            .firstChild {
              border-left: size(2) solid $header-bg;
            }

            .lastChild {
              border-right: size(2) solid $header-bg;
            }
          }

          &--must-run.sp-first {
            border-left: size(2) solid $header-bg;

            svg {
              transform: translate(2px, 3px);
            }
          }

          &--must-run.sp-last {
            border-right: size(2) solid $header-bg;
          }

          &--error {
            background-color: $error-lighter;
            border-top: size(1) solid $alert-warning-text;
            border-bottom: size(1) solid $alert-warning-text;
          }

          &--error.sp-first {
            border-left: size(1) solid $alert-warning-text;

            svg {
              transform: translate(2px);
            }
          }

          &--error.sp-last {
            border-right: size(1) solid $alert-warning-text;
          }
        }

        .sp-first {
          border-top-left-radius: size(2);
          border-bottom-left-radius: size(2);
        }

        .sp-last {
          border-top-right-radius: size(2);
          border-bottom-right-radius: size(2);
          margin-right: size(2);
        }
      }

      &__periods {
        position: relative;
        height: size(15);
        margin: size(2) 0;

        &-container {
          height: 50%;
          display: flex;
          margin: 0;
          padding: 0;
          justify-content: space-between;
        }

        &-hour {
          height: 100%;
          flex-grow: 1;
          border-left: size(1) solid $settlement-period-hour;

          &__value {
            position: absolute;
            bottom: -12px;
            transform: translateX(-50%);
            color: $lj-headline;
            font-size: $font-size-medium;

            &--highlighted {
              background-color: $alert-caution;
              border-radius: size(4);
              padding: 0 size(2);
            }
          }
        }

        &-mark {
          height: 50%;
          flex-grow: 1;
          border-left: 1px solid $settlement-period-hour;
        }
      }
    }
  }
}

.asset-timeline__tooltip {
  width: 200px;

  div:not(:last-child) {
    margin-bottom: $spacing-smaller;
  }

  &-date,
  &-status,
  &-capacity,
  &-error {
    display: flex;
    justify-content: space-between;
  }

  &-keyInfo {
    font-weight: $font-weight-500;
  }
  &-status &-keyInfo {
    text-transform: capitalize;
  }
}
